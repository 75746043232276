import React from "react"
import { graphql } from 'gatsby'
import LayoutHeadFoot from "../components/Shared/Layouts/LayoutHeadFoot";
import SEO from "../components/seo";
import FeaturesPage from "../components/FeaturesPage/FeaturesPage";


const FeaturesSubTemplate = ({ data, location, pageContext }) => {
    const featureSubData = data.strapiFeaturesSubPage;

    return (
        <>
            <SEO seo={featureSubData.metaData} />
            <LayoutHeadFoot>
                <FeaturesPage data={featureSubData} location={location} pageContext={pageContext} />
            </LayoutHeadFoot>
        </>
    )
}

export const query = graphql`
query SubQuery($url: String!) {
    strapiFeaturesSubPage(url: {eq: $url}) {
        content
        textButton
        metaData {
            metaTitle
            metaDescription
            shareImage {
              localFile {
                publicURL
              }
            }
        }
  }
}`

export default FeaturesSubTemplate;


